import { useState } from "react";
import { LoginRequest } from "../../pb/api_pb";
import "./style.css";
import { setToken } from "../../store/authSlice";
import { useDispatch } from "react-redux";
import { toast } from 'react-toastify';


function LoginPage({ setAuthenticated, apiClient }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const dispatch = useDispatch();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const req = new LoginRequest();
      req.setUsername(username);
      req.setPassword(password);

      apiClient.login(
        req,
        {},
        (err, response) => {
          if (err) {
            toast(err.message, {type: "error"}) 
            console.log(err.code);
            return;
          }
          const token = response.getToken()
          if (token) {
            dispatch(setToken(token));
            setAuthenticated(true);
          } else {
            setError("cannot get token");
          }
        }
      );
    } catch (error) {
      console.error(error)
      setError("Invalid username or password");
    }
  };

  return (
    <div className="login-page">
      <form className="login-form" onSubmit={handleSubmit}>
        <label>
          账号:
          <input
            type="text"
            value={username}
            onChange={(event) => setUsername(event.target.value)}
          />
        </label>
        <label>
          密码:
          <input
            type="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
        </label>
        <button type="submit">Login</button>
        {error && <div className="login-error">{error}</div>}
      </form>
    </div>
  );
}

export default LoginPage;
