/**
 * @fileoverview gRPC-Web generated client stub for api
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.21.12
// source: api.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.api = require('./api_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.api.ApiClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.api.ApiPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.RegisterRequest,
 *   !proto.api.RegisterResponse>}
 */
const methodDescriptor_Api_Register = new grpc.web.MethodDescriptor(
  '/api.Api/Register',
  grpc.web.MethodType.UNARY,
  proto.api.RegisterRequest,
  proto.api.RegisterResponse,
  /**
   * @param {!proto.api.RegisterRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.RegisterResponse.deserializeBinary
);


/**
 * @param {!proto.api.RegisterRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.RegisterResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.RegisterResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.ApiClient.prototype.register =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.Api/Register',
      request,
      metadata || {},
      methodDescriptor_Api_Register,
      callback);
};


/**
 * @param {!proto.api.RegisterRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.RegisterResponse>}
 *     Promise that resolves to the response
 */
proto.api.ApiPromiseClient.prototype.register =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.Api/Register',
      request,
      metadata || {},
      methodDescriptor_Api_Register);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.LoginRequest,
 *   !proto.api.LoginResponse>}
 */
const methodDescriptor_Api_Login = new grpc.web.MethodDescriptor(
  '/api.Api/Login',
  grpc.web.MethodType.UNARY,
  proto.api.LoginRequest,
  proto.api.LoginResponse,
  /**
   * @param {!proto.api.LoginRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.LoginResponse.deserializeBinary
);


/**
 * @param {!proto.api.LoginRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.LoginResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.LoginResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.ApiClient.prototype.login =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.Api/Login',
      request,
      metadata || {},
      methodDescriptor_Api_Login,
      callback);
};


/**
 * @param {!proto.api.LoginRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.LoginResponse>}
 *     Promise that resolves to the response
 */
proto.api.ApiPromiseClient.prototype.login =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.Api/Login',
      request,
      metadata || {},
      methodDescriptor_Api_Login);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.LogoutRequest,
 *   !proto.api.LogoutResponse>}
 */
const methodDescriptor_Api_Logout = new grpc.web.MethodDescriptor(
  '/api.Api/Logout',
  grpc.web.MethodType.UNARY,
  proto.api.LogoutRequest,
  proto.api.LogoutResponse,
  /**
   * @param {!proto.api.LogoutRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.LogoutResponse.deserializeBinary
);


/**
 * @param {!proto.api.LogoutRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.LogoutResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.LogoutResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.ApiClient.prototype.logout =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.Api/Logout',
      request,
      metadata || {},
      methodDescriptor_Api_Logout,
      callback);
};


/**
 * @param {!proto.api.LogoutRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.LogoutResponse>}
 *     Promise that resolves to the response
 */
proto.api.ApiPromiseClient.prototype.logout =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.Api/Logout',
      request,
      metadata || {},
      methodDescriptor_Api_Logout);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.SendWaitRequest,
 *   !proto.api.SendWaitResponse>}
 */
const methodDescriptor_Api_SendWait = new grpc.web.MethodDescriptor(
  '/api.Api/SendWait',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.api.SendWaitRequest,
  proto.api.SendWaitResponse,
  /**
   * @param {!proto.api.SendWaitRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.SendWaitResponse.deserializeBinary
);


/**
 * @param {!proto.api.SendWaitRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.api.SendWaitResponse>}
 *     The XHR Node Readable Stream
 */
proto.api.ApiClient.prototype.sendWait =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/api.Api/SendWait',
      request,
      metadata || {},
      methodDescriptor_Api_SendWait);
};


/**
 * @param {!proto.api.SendWaitRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.api.SendWaitResponse>}
 *     The XHR Node Readable Stream
 */
proto.api.ApiPromiseClient.prototype.sendWait =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/api.Api/SendWait',
      request,
      metadata || {},
      methodDescriptor_Api_SendWait);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.DeleteConversationRequest,
 *   !proto.api.DeleteConversationResponse>}
 */
const methodDescriptor_Api_DeleteConversation = new grpc.web.MethodDescriptor(
  '/api.Api/DeleteConversation',
  grpc.web.MethodType.UNARY,
  proto.api.DeleteConversationRequest,
  proto.api.DeleteConversationResponse,
  /**
   * @param {!proto.api.DeleteConversationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.DeleteConversationResponse.deserializeBinary
);


/**
 * @param {!proto.api.DeleteConversationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.DeleteConversationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.DeleteConversationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.ApiClient.prototype.deleteConversation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.Api/DeleteConversation',
      request,
      metadata || {},
      methodDescriptor_Api_DeleteConversation,
      callback);
};


/**
 * @param {!proto.api.DeleteConversationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.DeleteConversationResponse>}
 *     Promise that resolves to the response
 */
proto.api.ApiPromiseClient.prototype.deleteConversation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.Api/DeleteConversation',
      request,
      metadata || {},
      methodDescriptor_Api_DeleteConversation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.GetConversationsRequest,
 *   !proto.api.GetConversationsResponse>}
 */
const methodDescriptor_Api_GetConversations = new grpc.web.MethodDescriptor(
  '/api.Api/GetConversations',
  grpc.web.MethodType.UNARY,
  proto.api.GetConversationsRequest,
  proto.api.GetConversationsResponse,
  /**
   * @param {!proto.api.GetConversationsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.GetConversationsResponse.deserializeBinary
);


/**
 * @param {!proto.api.GetConversationsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.GetConversationsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.GetConversationsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.ApiClient.prototype.getConversations =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.Api/GetConversations',
      request,
      metadata || {},
      methodDescriptor_Api_GetConversations,
      callback);
};


/**
 * @param {!proto.api.GetConversationsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.GetConversationsResponse>}
 *     Promise that resolves to the response
 */
proto.api.ApiPromiseClient.prototype.getConversations =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.Api/GetConversations',
      request,
      metadata || {},
      methodDescriptor_Api_GetConversations);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.GetConversationRequest,
 *   !proto.api.GetConversationResponse>}
 */
const methodDescriptor_Api_GetConversation = new grpc.web.MethodDescriptor(
  '/api.Api/GetConversation',
  grpc.web.MethodType.UNARY,
  proto.api.GetConversationRequest,
  proto.api.GetConversationResponse,
  /**
   * @param {!proto.api.GetConversationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.GetConversationResponse.deserializeBinary
);


/**
 * @param {!proto.api.GetConversationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.GetConversationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.GetConversationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.ApiClient.prototype.getConversation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.Api/GetConversation',
      request,
      metadata || {},
      methodDescriptor_Api_GetConversation,
      callback);
};


/**
 * @param {!proto.api.GetConversationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.GetConversationResponse>}
 *     Promise that resolves to the response
 */
proto.api.ApiPromiseClient.prototype.getConversation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.Api/GetConversation',
      request,
      metadata || {},
      methodDescriptor_Api_GetConversation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.GenConversationTitleRequest,
 *   !proto.api.GenConversationTitleResponse>}
 */
const methodDescriptor_Api_GenConversationTitle = new grpc.web.MethodDescriptor(
  '/api.Api/GenConversationTitle',
  grpc.web.MethodType.UNARY,
  proto.api.GenConversationTitleRequest,
  proto.api.GenConversationTitleResponse,
  /**
   * @param {!proto.api.GenConversationTitleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.GenConversationTitleResponse.deserializeBinary
);


/**
 * @param {!proto.api.GenConversationTitleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.GenConversationTitleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.GenConversationTitleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.ApiClient.prototype.genConversationTitle =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.Api/GenConversationTitle',
      request,
      metadata || {},
      methodDescriptor_Api_GenConversationTitle,
      callback);
};


/**
 * @param {!proto.api.GenConversationTitleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.GenConversationTitleResponse>}
 *     Promise that resolves to the response
 */
proto.api.ApiPromiseClient.prototype.genConversationTitle =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.Api/GenConversationTitle',
      request,
      metadata || {},
      methodDescriptor_Api_GenConversationTitle);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.UpdateConversationTitleRequest,
 *   !proto.api.UpdateConversationTitleResponse>}
 */
const methodDescriptor_Api_UpdateConversationTitle = new grpc.web.MethodDescriptor(
  '/api.Api/UpdateConversationTitle',
  grpc.web.MethodType.UNARY,
  proto.api.UpdateConversationTitleRequest,
  proto.api.UpdateConversationTitleResponse,
  /**
   * @param {!proto.api.UpdateConversationTitleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.UpdateConversationTitleResponse.deserializeBinary
);


/**
 * @param {!proto.api.UpdateConversationTitleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.UpdateConversationTitleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.UpdateConversationTitleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.ApiClient.prototype.updateConversationTitle =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.Api/UpdateConversationTitle',
      request,
      metadata || {},
      methodDescriptor_Api_UpdateConversationTitle,
      callback);
};


/**
 * @param {!proto.api.UpdateConversationTitleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.UpdateConversationTitleResponse>}
 *     Promise that resolves to the response
 */
proto.api.ApiPromiseClient.prototype.updateConversationTitle =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.Api/UpdateConversationTitle',
      request,
      metadata || {},
      methodDescriptor_Api_UpdateConversationTitle);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.UpdateConversationFormatRequest,
 *   !proto.api.UpdateConversationFormatResponse>}
 */
const methodDescriptor_Api_UpdateConversationFormat = new grpc.web.MethodDescriptor(
  '/api.Api/UpdateConversationFormat',
  grpc.web.MethodType.UNARY,
  proto.api.UpdateConversationFormatRequest,
  proto.api.UpdateConversationFormatResponse,
  /**
   * @param {!proto.api.UpdateConversationFormatRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.UpdateConversationFormatResponse.deserializeBinary
);


/**
 * @param {!proto.api.UpdateConversationFormatRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.UpdateConversationFormatResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.UpdateConversationFormatResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.ApiClient.prototype.updateConversationFormat =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.Api/UpdateConversationFormat',
      request,
      metadata || {},
      methodDescriptor_Api_UpdateConversationFormat,
      callback);
};


/**
 * @param {!proto.api.UpdateConversationFormatRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.UpdateConversationFormatResponse>}
 *     Promise that resolves to the response
 */
proto.api.ApiPromiseClient.prototype.updateConversationFormat =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.Api/UpdateConversationFormat',
      request,
      metadata || {},
      methodDescriptor_Api_UpdateConversationFormat);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.GetQueueNumberRequest,
 *   !proto.api.GetQueueNumberResponse>}
 */
const methodDescriptor_Api_GetQueueNumber = new grpc.web.MethodDescriptor(
  '/api.Api/GetQueueNumber',
  grpc.web.MethodType.UNARY,
  proto.api.GetQueueNumberRequest,
  proto.api.GetQueueNumberResponse,
  /**
   * @param {!proto.api.GetQueueNumberRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.GetQueueNumberResponse.deserializeBinary
);


/**
 * @param {!proto.api.GetQueueNumberRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.GetQueueNumberResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.GetQueueNumberResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.ApiClient.prototype.getQueueNumber =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.Api/GetQueueNumber',
      request,
      metadata || {},
      methodDescriptor_Api_GetQueueNumber,
      callback);
};


/**
 * @param {!proto.api.GetQueueNumberRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.GetQueueNumberResponse>}
 *     Promise that resolves to the response
 */
proto.api.ApiPromiseClient.prototype.getQueueNumber =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.Api/GetQueueNumber',
      request,
      metadata || {},
      methodDescriptor_Api_GetQueueNumber);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.GetConversationIdRequest,
 *   !proto.api.GetConversationIdResponse>}
 */
const methodDescriptor_Api_GetConversationId = new grpc.web.MethodDescriptor(
  '/api.Api/GetConversationId',
  grpc.web.MethodType.UNARY,
  proto.api.GetConversationIdRequest,
  proto.api.GetConversationIdResponse,
  /**
   * @param {!proto.api.GetConversationIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.GetConversationIdResponse.deserializeBinary
);


/**
 * @param {!proto.api.GetConversationIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.GetConversationIdResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.GetConversationIdResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.ApiClient.prototype.getConversationId =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.Api/GetConversationId',
      request,
      metadata || {},
      methodDescriptor_Api_GetConversationId,
      callback);
};


/**
 * @param {!proto.api.GetConversationIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.GetConversationIdResponse>}
 *     Promise that resolves to the response
 */
proto.api.ApiPromiseClient.prototype.getConversationId =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.Api/GetConversationId',
      request,
      metadata || {},
      methodDescriptor_Api_GetConversationId);
};


module.exports = proto.api;

