import { createSlice } from '@reduxjs/toolkit'
import DB from '../db'

const token = DB.getToken()

const isPlus = (token) => token?JSON.parse(JSON.parse(atob(token.split('.')[1]))?.sub)?.Plus: false;

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    token: token,
    plus: isPlus(token),
  },
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload
      state.plus = isPlus(action.payload)
    },
  }
})

// Action creators are generated for each case reducer function
export const { setToken } = authSlice.actions

export default authSlice.reducer
